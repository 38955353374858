import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Box, Flex } from 'rebass/styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import Section from '../components/section'
import Container from '../components/container'
import SectionTitle from '../components/section-title'

const KontaktPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          company {
            name
            id
            address {
              street
              city
            }
          }
          contactPerson {
            name
            position
            email
            phone
          }
        }
      }
      markdownRemark(frontmatter: { pageType: { eq: "contact" } }) {
        frontmatter {
          title
          subtitle
        }
      }
      backgroundImage: file(relativePath: { eq: "landscape.jpg" }) {
        childImageSharp {
          fluid(quality: 85, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const { title, subtitle } = data.markdownRemark.frontmatter
  const { company } = data.site.siteMetadata
  const backgroundImage = data.backgroundImage.childImageSharp.fluid

  return (
    <Layout>
      <SEO title={title} />

      <Hero
        backgroundImage={backgroundImage}
        title={title}
        subtitle={subtitle}
      />

      <Section variant="light">
        <Container>
          <Flex>
            <Box width={[1, 1, 1 / 2]}>
              <SectionTitle as="h3" mb={3} fontSize={3} textAlign="left">{company.name}</SectionTitle>

              <ul>
                <li>{company.address.street}</li>
                <li>{company.address.city}</li>
                <li>{`IČ: ${company.id}`}</li>
              </ul>
            </Box>
          </Flex>
        </Container>
      </Section>
    </Layout>
  )
}

export default KontaktPage
