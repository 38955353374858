import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  Box, Flex, Heading, Text,
} from 'rebass/styled-components'
import { rgba, darken } from 'polished'
import BackgroundImage from 'gatsby-background-image'

import Container from './container'

const HeroWrapper = styled(Box)`
  width: 100%;
`

const StyledBackground = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

const HeroBodyWrapper = styled(Box)`
  background-color: ${({ theme }) => rgba(darken(4, theme.colors.brown), 0.4)};
`

const HeroBody = styled(Flex)``

const Hero = ({ backgroundImage, title, subtitle }) => {
  return (
    <HeroWrapper>
      <StyledBackground
        Tag="div"
        fluid={backgroundImage}
        backgroundColor="#040e18"
      >
        <HeroBodyWrapper>
          <Container>
            <HeroBody flexDirection="column" alignItems="flex-start" width={[1, 1, 2 / 3]} pt={6} pb={5}>
              {title && <Heading as="h1" mb={3} color="white" fontSize={[4, 5, 7]}>{title}</Heading>}

              {subtitle && <Text dangerouslySetInnerHTML={{ __html: subtitle }} color="white" lineHeight="1.5" fontWeight="bold" fontSize={[2, 3, 4]} />}
            </HeroBody>
          </Container>
        </HeroBodyWrapper>
      </StyledBackground>
    </HeroWrapper>
  )
}

Hero.propTypes = {
  backgroundImage: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

export default Hero
